import React from 'react';
import css from './bulletPointsFormatter.module.css';

const suspiciousDomains = ['example-malicious-site.com', 'suspicious-domain.net'];
const suspiciousExtensions = ['.exe', '.bat', '.msi', '.scr'];
const suspiciousPathPatterns = ['/download', '/free-offer', '/click-here'];
const suspiciousProtocols = ['ftp:', 'file:'];
const urlShorteners = ['bit.ly', 'tinyurl.com', 'goo.gl'];

const isLinkSuspicious = url => {
  try {
    const parsedUrl = new URL(url.startsWith('http') ? url : `http://${url}`);
    const domain = parsedUrl.hostname;
    const extension = url
      .split('.')
      .pop()
      .toLowerCase();
    const protocol = parsedUrl.protocol;
    const path = parsedUrl.pathname;
    const query = parsedUrl.search;

    const domainIsSuspicious = suspiciousDomains.some(d => domain.includes(d));
    const extensionIsSuspicious = suspiciousExtensions.includes(`.${extension}`);
    const protocolIsSuspicious = suspiciousProtocols.includes(protocol);
    const pathIsSuspicious = suspiciousPathPatterns.some(pattern => path.includes(pattern));
    const queryIsSuspicious = query.includes('download') || query.includes('free');
    const isShortenedUrl = urlShorteners.some(shortener => domain === shortener);

    return (
      domainIsSuspicious ||
      extensionIsSuspicious ||
      protocolIsSuspicious ||
      pathIsSuspicious ||
      queryIsSuspicious ||
      isShortenedUrl
    );
  } catch (error) {
    return true;
  }
};

const BulletPointsFormatter = ({ text }) => {
  if (!text || text?.length == 0 || typeof text !== 'string') {
    return <></>;
  }

  const bulletPointChars = new Set(['-', '•', '+']);
  const lines = text.split('\n');

  const processTextFormatting = str => {
    const boldRegex = /(\*\*|__)(.+?)\1/g;
    const italicRegex = /(\*|_)(.+?)\1/g;
    const markdownLinkRegex = /\[(.+?)\]\((.+?)\)/g;
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|((?<=\s|^)\S+\.(?:com|org|net|edu|gov)\S*)/gi;

    let lastIndex = 0;
    const result = [];
    let match;

    const processMatch = (matchResult, regex) => {
      if (matchResult.index > lastIndex) {
        result.push(str.slice(lastIndex, matchResult.index));
      }
      if (regex === markdownLinkRegex) {
        const linkText = matchResult[1];
        const linkUrl = matchResult[2];
        result.push(createLinkElement(linkUrl, linkText, result.length));
      } else if (regex === urlRegex) {
        const linkUrl = matchResult[0].startsWith('http')
          ? matchResult[0]
          : `http://${matchResult[0]}`;
        result.push(createLinkElement(linkUrl, matchResult[0], result.length));
      } else if (regex === boldRegex) {
        result.push(<strong key={result.length}>{matchResult[2]}</strong>);
      } else if (regex === italicRegex) {
        result.push(<em key={result.length}>{matchResult[2]}</em>);
      }
      lastIndex = matchResult.index + matchResult[0].length;
    };

    const createLinkElement = (url, text, key) => {
      const isSuspicious = isLinkSuspicious(url);
      return (
        <a
          key={key}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={`${css.link} ${isSuspicious ? css.suspiciousLink : ''}`}
          title={isSuspicious ? 'This link may be suspicious' : ''}
        >
          {text}
        </a>
      );
    };

    while (
      (match = boldRegex.exec(str)) !== null ||
      (match = italicRegex.exec(str)) !== null ||
      (match = markdownLinkRegex.exec(str)) !== null ||
      (match = urlRegex.exec(str)) !== null
    ) {
      if (match[0].startsWith('[')) {
        processMatch(match, markdownLinkRegex);
      } else if (
        match[0].startsWith('http') ||
        match[0].startsWith('www') ||
        /\.\w+/.test(match[0])
      ) {
        processMatch(match, urlRegex);
      } else if (match[0].startsWith('*') || match[0].startsWith('_')) {
        processMatch(
          match,
          match[0].startsWith('**') || match[0].startsWith('__') ? boldRegex : italicRegex
        );
      }
    }

    if (lastIndex < str.length) {
      result.push(str.slice(lastIndex));
    }

    return result;
  };

  const formattedLines = lines.map((line, index) => {
    const trimmedLine = line.trim();
    if (bulletPointChars.has(trimmedLine.charAt(0))) {
      const [bullet, ...rest] = trimmedLine.split(/\s+/);
      return (
        <div key={index} className={css.bulletPoint}>
          {bullet} {processTextFormatting(rest.join(' '))}
        </div>
      );
    } else {
      return <div key={index}>{processTextFormatting(trimmedLine)}</div>;
    }
  });

  return <div className={css.formattedText}>{formattedLines}</div>;
};

export default BulletPointsFormatter;
