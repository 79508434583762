import React, { useState, useEffect } from 'react';
import getCountryCodes from '../../translations/countryCodes';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import omit from 'lodash/omit';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  stringify,
  parse,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import ActionBarMaybe from './ActionBarMaybe';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperFooter,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

/**Newly migrated components*/
import SectionHeading from './ListingPageComponents/SectionHeading/SectionHeading';
import ReviewRatings from './ListingPageComponents/ReviewRatings/ReviewRatings.jsx';
import ImagesDisplay from './ListingPageComponents/ImageDisplay.jsx/ImagesDisplay';
import ListingDetailsHeader from './ListingPageComponents/ListingDetailsHeader/ListingDetailsHeader';
import AccomodationDetails from './ListingPageComponents/AccomodationDetails/AccomodationDetails';
import Activities from './ListingPageComponents/Activities/Activities';
import ExperienceMockUpSection from './ListingPageComponents/ExperienceMockUpSection/ExperienceMockUpSection.jsx';

import Offerings from './ListingPageComponents/Offerings/Offerings';
import MapDisplay from './ListingPageComponents/MapDisplay/MapDisplay';
import HowToGetThere from './ListingPageComponents/HowToGetThere/HowToGetThere';
import OnSite from './ListingPageComponents/OnSite/OnSite';
import AboutProject from './ListingPageComponents/AboutProject/AboutProject';
import ListingVideo from './ListingPageComponents/ListingVideo/ListingVideo';
import Donate from './ListingPageComponents/Donate/Donate';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  speculateTransactionLP,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmit,
} from './ListingPage.shared';

import SectionReviews from '../ListingPage/ListingPageComponents/Reviews/Reviews';
import css from './ListingPage.module.css';
import AuthModal from '../../components/AuthModal/AuthModal';
import Cookies from 'universal-cookie';
import heartImg from '../../assets/heart.png';
import heartImgFill from '../../assets/heart_filled.webp';
import DiscoverListings from './ListingPageComponents/ListingSuggestions/discoverListingSuggestions';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { ensureCurrentUser } from '../../util/data';
import Notification from '../../components/notification-popup/notification.js';
import whatsappIcon from '../../assets/icons/whatsapp-icon2.png';
import { extractDaysAndNights } from '../../util/listingPages.util.js';
import Footer from '../../components/Footer/FooterNew.jsx';
import emailWhiteIcon from '../../assets/email-white.svg';
import QuestionsComponent from './FAQDisplay/faqDisplay.jsx';
import BulletPointsFormatter from '../../util/formatters/bulletPointsFormatter/bulletPointsFormatter.jsx';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const cookies = new Cookies();
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [showModalFromOutside, setShowModalFromOutside] = useState(false);
  const [orderValues, setOrderValues] = useState('');
  const [favorite, Setfavorite] = useState();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [callFav, setCallFav] = useState(false);
  const [notification, setNotification] = useState(false);

  const openModalFromOutside = value => {
    setShowModalFromOutside(value);
  };

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    fetchSpeculatedTransaction,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    config,
    routeConfiguration,
    listings,
  } = props;

  const { lang } = useParams();

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  // const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const isExperienceType =
    currentListing.attributes?.publicData?.listingType == 'impact-experience';
  const countryCodes = getCountryCodes(config.locale);
  const user = ensureCurrentUser(currentUser);
  let isFavArray = user.attributes.profile?.publicData?.favoritList ?? [];
  useEffect(() => {
    let isFav = isFavArray.findIndex(item => item.listId === listingId.uuid);
    if (isFav !== -1) {
      Setfavorite(true);
    }
  }, [user, isFavArray]);
  const { onUpdateProfile } = props;
  const favoriteFunction = async () => {
    if (!user.attributes.profile.publicData.favoritList) {
      user.attributes.profile.publicData.favoritList = [];
    }
    const favoritList = user.attributes.profile.publicData.favoritList;
    const index = favoritList.findIndex(item => item.listId === listingId.uuid);
    if (index !== -1) {
      favoritList.splice(index, 1);
      Setfavorite(false);
      await onUpdateProfile({ publicData: { favoritList } }).then(resp => {
        setNotification(intl.formatMessage({ id: 'favoritePage.removed' }));
      });
    } else {
      favoritList.push({ listId: listingId.uuid });
      Setfavorite(true);
      await onUpdateProfile({ publicData: { favoritList } }).then(resp => {
        setNotification(intl.formatMessage({ id: 'favoritePage.added' }));
      });
    }
  };
  //get country name and set it as a state
  const [countrycodeLang, setCountrycodeLang] = useState(' ');
  const language = intl.locale.split('-')[0];
  const countriesInLanguage = getCountryCodes(language);
  const [countrynameNew, setCountrynameNew] = useState('');
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const [bookingDisabled, setBookingDisabled] = useState(false);
  const [showBookingNoAvailableError, setShowBookingNoAvailableError] = useState(false);

  useEffect(() => {
    const countryCodeTemp =
      currentListing && currentListing.attributes.publicData.country
        ? currentListing.attributes.publicData.country
        : [];
    const newCountryName = getCountryName(countryCodeTemp);
    setCountrycodeLang(countryCodeTemp);
    const codeAndCountry = countriesInLanguage.find(country => country.code === countrycodeLang);
    if (codeAndCountry) {
      setCountrynameNew(codeAndCountry.name);
    }
  }, [currentListing, countriesInLanguage, countrycodeLang]);

  function getCountryName(countryCode) {
    const country = countryCodes.find(country => country.code === countryCode);
    return country ? country.name : 'Country code not found';
  }

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );
  //from here until line 247  is only for translate
  const newTitle = title.split('• ');
  const roomtypeTranslation = isExperienceType
    ? null
    : intl.formatMessage({ id: `roomtypes.${newTitle[1]}` });

  //is experience
  const experienceType = newTitle[1];
  const experienceTitle =
    experienceType === '2 Days 1 Night'
      ? intl.formatMessage({ id: 'experienceTitle.2d1n' })
      : experienceType === '3 Days 2 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.3d2n' })
      : experienceType === '4 Days 3 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.4d3n' })
      : experienceType === '5 Days 4 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.5d4n' })
      : experienceType === '6 Days 5 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.6d5n' })
      : experienceType === '7 Days 6 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.1w' })
      : experienceType === '14 Days 13 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.2w' })
      : experienceType === '21 Days 20 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.3w' })
      : null;

  const titleSecondPart = isExperienceType ? experienceTitle : roomtypeTranslation;
  const newTitle2 = newTitle[0] + '• ' + titleSecondPart;
  const newRichTitle = (
    <span>
      {richText(newTitle2, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  /* const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  }); */
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    lang,
  });

  const closeOrderModal = (history, location) => {
    const { pathname, search, state } = location;
    const searchParams = omit(parse(search), 'orderOpen');
    const searchString = `?${stringify(searchParams)}`;
    history.push(`${pathname}${searchString}`, state);
  };

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (!currentUser?.id?.uuid) {
      setAuthModalOpen(true);
      setOrderValues(values);
      closeOrderModal(history, location);
      return;
    }

    if (isOwnListing || isCurrentlyClosed) {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    } else {
      setShowPriceDetails(true);

      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );

  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  /*   const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label })); */
  const googleReviews = publicData?.location?.reviews ?? [];
  const googleRating = publicData?.location?.rating ?? 0;

  const projectInformationRaw1 = ensureListing(currentListing.author);
  const projectInformationRaw2 = ensureListing(projectInformationRaw1.attributes.profile);
  const projectInformation = projectInformationRaw2.publicData;
  const activitiesEn = publicData && publicData.activities ? publicData.activities : [];
  const activitiesDe = publicData && publicData.activities_de ? publicData.activities_de : [];

  let projectAccomodationType = null;
  let ProjectRoomtype = null;

  const hostLink = (
    <NamedLink
      className={css.authorNameLink}
      name="ListingPage"
      params={params}
      to={{ hash: '#host' }}
    >
      {authorDisplayName}
    </NamedLink>
  );

  const currentCity =
    currentListing &&
    currentListing.attributes.publicData &&
    currentListing.attributes.publicData.city
      ? currentListing.attributes.publicData.city
      : 'No city available';
  const howToGetThereEn = publicData && publicData.arrival ? publicData.arrival : [];
  const howToGetThereDe = publicData && publicData.arrival_de ? publicData.arrival_de : [];
  /*   const currentPrice =
    currentListing && currentListing.attributes ? currentListing.attributes.price.amount / 100 : [];
  const currentCurrency =
    currentListing &&
    currentListing.attributes &&
    currentListing.attributes.price.currency === 'EUR'
      ? String.fromCharCode(8364)
      : '$';
  const currentDayUnit = cookies.get('language') === 'en' ? 'per Night' : 'per Nacht'; */
  const actionBar = listingId ? (
    <div onClick={e => e.stopPropagation()} className={css.actionBarParent}>
      <ActionBarMaybe
        className={css.actionBar}
        isOwnListing={isOwnListing}
        listing={currentListing}
        editParams={{
          id: listingId.uuid,

          slug: listingSlug,

          type: listingPathParamType,

          tab: listingTab,
        }}
      />
    </div>
  ) : null;

  const fetchBookingAvailabilityData = async data => {
    if (currentUser) {
      const { listingId, orderData } = data;
      const { bookingEnd, bookingStart, seats } = orderData;
      let transitionName = 'transition/paypal-payment';
      let params = {
        bookingDates: { bookingEnd, bookingStart },
        seats,
        listingId,
        cardToken: 'CheckoutPage_speculative_card_token',
      };
      let processAlias =
        config.listing?.listingTypes[0]?.transactionType?.alias ?? 'default-booking/release-1';
      let isPrivileged = true;
      let response = await fetchSpeculatedTransaction(
        params,
        processAlias,
        null,
        transitionName,
        isPrivileged
      );
      if (response.code != 200) {
        setBookingDisabled(true);
        setShowBookingNoAvailableError(true);
      } else {
        setBookingDisabled(false);
        setShowBookingNoAvailableError(false);
      }
    } else {
      setBookingDisabled(false);
      setShowBookingNoAvailableError(false);
    }
    onFetchTransactionLineItems(data);
  };
  const whatsappEncodedMessage = encodeURIComponent(
    `Hello, i have a question about the ${currentListing.attributes.title} listing !`
  );
  const whatsappNumber = '+4915730977660';
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${whatsappEncodedMessage}`;

  const cancellationPolicy =
    props.currentLanguage === 'de'
      ? publicData?.cancellationPolicy_de || publicData?.cancellationPolicy
      : publicData?.cancellationPolicy || publicData?.cancellationPolicy_de;
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <div
        data-elbglobals="pagetype:listing"
        data-elbcontext="booking:detail"
        data-elb="accm"
        data-elbaction={`load:view-${currentListing?.attributes?.title}-uuid-${currentListing?.id?.uuid};`}
      >
        <LayoutSingleColumn
          topbar={topbar}
          footer={
            <div
              data-elb="section"
              data-elb-section="name:footer;position:7"
              data-elbaction="visible"
            >
              <Footer />
            </div>
          }
        >
          <AuthModal
            isOpen={authModalOpen}
            setOpen={setAuthModalOpen}
            redirectToCheckout={() => {
              if (orderValues) {
                handleOrderSubmit(orderValues);
              } else if (callFav) {
                favoriteFunction();
                setCallFav(false);
              }
            }}
          />
          {notification && (
            <Notification message={notification} onClose={() => setNotification(null)} />
          )}
          {/**/}
          <LayoutWrapperMain className={css.pageRoot}>
            <div className={css.contentContainer} data-elb-accomodation={`name:${listingSlug}`}>
              {/**/}
              <div
                className={css.topContent1}
                data-elb-accomodation={`city:${currentCity};country:${countrynameNew}`}
              >
                <div className={css.favButtonContainer}>
                  <button
                    className={css.favButton}
                    onClick={() => {
                      if (isAuthenticated) {
                        favoriteFunction();
                      } else {
                        setCallFav(true);
                        setAuthModalOpen(true);
                      }
                    }}
                  >
                    {' '}
                    <img src={favorite ? heartImgFill : heartImg} className={css.favImg} />{' '}
                  </button>
                </div>
              </div>
              <div className={css.topContent}>
                <SectionHeading
                  projectInformation={projectInformation}
                  richTitle={newRichTitle}
                  hostLink={hostLink}
                  showContactUser={showContactUser}
                  onContactUser={onContactUser}
                  isExperienceType={isExperienceType}
                  currentListing={currentListing}
                  currentLanguage={props.currentLanguage}
                />
              </div>
              {/**/}{' '}
              <div
                className={css.bottomContent}
                data-elbaction={`wait(100):isAuthenticated:${
                  currentUser?.attributes?.email ? currentUser?.attributes?.email : 'false'
                }`}
              >
                <ReviewRatings
                  currentListing={currentListing}
                  reviews={reviews}
                  googleReviews={googleReviews}
                  rating={googleRating}
                  onShowModalFromOutside={openModalFromOutside}
                />
                {currentListing && currentListing.attributes.publicData && (
                  <h3 className={css.cityCountry}>
                    {currentCity}, {countrynameNew}
                  </h3>
                )}
              </div>
              {actionBar}
              <ImagesDisplay onListing={currentListing} />
              {/**Current listing's description section
               * leftSection: About accomodation and overnight stay support
               * rightSection: BookingSection
               */}
              <div className={css.descriptionSection} id="descriptionSection">
                {/**Listing Details header section */}
                <div className={css.listingDetailsHeaderSection}>
                  <ListingDetailsHeader
                    currentListing={currentListing}
                    ProjectRoomtype={ProjectRoomtype}
                    projectAccomodationType={projectAccomodationType}
                    roomTypeRaw={publicData?.roomtype}
                    isExperienceType={isExperienceType}
                  />
                </div>

                {/**!------Description, Details and booking section --------!*/}
                <div className={css.accomodationDescWrapper}>
                  <div
                    className={css.leftSection}
                    data-elb-accomodation={`price:${(
                      Number(currentListing.attributes.price.amount) / 100
                    ).toFixed(2)}`}
                  ></div>
                  {/*here comes the two sides  */}
                  <div className={css.leftRightContainer}>
                    <div className={css.containerLeft}>
                      <AccomodationDetails
                        currentListing={currentListing}
                        listings={listings}
                        listingConfig={currentListing}
                        activitiesEn={activitiesEn}
                        activitiesDe={activitiesDe}
                        isExperienceType={isExperienceType}
                      />
                      {!isExperienceType && (
                        <>
                          <Activities
                            activitiesEn={activitiesEn}
                            activitiesDe={activitiesDe}
                            isExperienceType={isExperienceType}
                          />
                        </>
                      )}

                      {!isExperienceType && (
                        <>
                          <ExperienceMockUpSection />
                          <Offerings currentListing={currentListing} publicData={publicData} />
                        </>
                      )}
                    </div>
                    <div
                      className={css.containerRight}
                      data-elb-accomodation={`room_type:${
                        publicData?.roomType ? publicData?.roomType : publicData?.roomtype
                      }`}
                    >
                      <OrderPanel
                        className={css.productOrderPanel}
                        listing={currentListing}
                        isOwnListing={isOwnListing}
                        onSubmit={handleOrderSubmit}
                        authorLink={
                          <NamedLink
                            className={css.authorNameLink}
                            name="ListingPage"
                            params={params}
                            to={{ hash: '#author' }}
                          >
                            {authorDisplayName}
                          </NamedLink>
                        }
                        title={richTitle}
                        titleDesktop={
                          <H4 as="h1" className={css.orderPanelTitle}>
                            {newRichTitle}
                          </H4>
                        }
                        author={ensuredAuthor}
                        onManageDisableScrolling={onManageDisableScrolling}
                        onContactUser={onContactUser}
                        monthlyTimeSlots={monthlyTimeSlots}
                        onFetchTimeSlots={onFetchTimeSlots}
                        onFetchTransactionLineItems={fetchBookingAvailabilityData}
                        lineItems={lineItems}
                        fetchLineItemsInProgress={fetchLineItemsInProgress}
                        fetchLineItemsError={fetchLineItemsError}
                        marketplaceCurrency={config.currency}
                        dayCountAvailableForBooking={config.dayCountAvailableForBooking}
                        marketplaceName={config.marketplaceName}
                        setAuthModalOpen={setAuthModalOpen}
                        currentUser={currentUser}
                        ProjectRoomtype={ProjectRoomtype}
                        roomTypeRaw={publicData?.roomtype}
                        bedamount={publicData?.bedamount}
                        showPriceDetails={showPriceDetails}
                        bookingDisabled={bookingDisabled}
                        showBookingNoAvailableError={showBookingNoAvailableError}
                      />
                      <div className={css.whatsappWrapper}>
                        <div className={css.whatsAppLink}>
                          <div className={css.whatsappContent}>
                            <a
                              href={whatsappLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={css.whatsappLinkRef}
                            >
                              <img
                                src={whatsappIcon}
                                className={css.whatsappIcon}
                                alt="WhatsApp Icon"
                              />
                              WhatsApp{' '}
                            </a>
                          </div>
                        </div>
                        <div className={css.EmailLink}>
                          <div className={css.emailContent}>
                            <a
                              href="mailto:booking@socialbnb.de"
                              target="_blank"
                              rel="noopener noreferrer"
                              className={css.whatsappLinkRef}
                            >
                              <img
                                src={emailWhiteIcon}
                                className={css.emailIcon}
                                alt="WhatsApp Icon"
                              />
                              E-mail{' '}
                            </a>
                          </div>
                        </div>

                        <div className={css.whatsappInfo}>
                          <FormattedMessage id="listingPage.whatsappInfo" />
                        </div>
                      </div>
                    </div>
                    {/*!------Booking row wrapper-----!*/}
                  </div>
                  {isExperienceType && (
                    <div
                      className={css.listingVideoWrapper}
                      data-elbaction="visible:vis accm-ytvideo"
                    >
                      <ListingVideo currentListing={currentListing} />
                    </div>
                  )}
                  <div className={css.mapContainer} data-elbaction="visible:vis accm-location">
                    <MapDisplay
                      geolocation={geolocation}
                      publicData={publicData}
                      listingId={currentListing.id}
                    />
                  </div>

                  <div className={css.onSiteHowtoGet}>
                    <HowToGetThere
                      howToGetThereEn={howToGetThereEn}
                      howToGetThereDe={howToGetThereDe}
                    />
                    <OnSite publicData={publicData} />
                  </div>
                  <div className={css.reviewsContainer}>
                    <SectionReviews
                      currentListing={currentListing}
                      reviews={reviews}
                      googleReviews={googleReviews}
                      rating={googleRating}
                      showModalFromOutside={showModalFromOutside}
                      closeModalFromOutside={openModalFromOutside}
                      currentLanguage={props.currentLanguage}
                    />
                  </div>
                  <br />
                  {isExperienceType && (
                    <div>
                      <h2>
                        <FormattedMessage id="editlistingPage.cancellationLabel" />
                      </h2>
                      <div>
                        <BulletPointsFormatter text={cancellationPolicy} />
                      </div>
                    </div>
                  )}
                  {isExperienceType && currentListing?.attributes?.publicData?.FAQs?.length > 0 ? (
                    <div className={css.faqWrapper}>
                      <h2>FAQ</h2>
                      <QuestionsComponent
                        questions={currentListing?.attributes?.publicData?.FAQs}
                        currentLanguage={props.currentLanguage}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <br />
              <div
                id="aboutProjectComponent"
                className={css.aboutProjectWrapper}
                data-elbaction="visible:vis accm-projecttext"
              >
                <AboutProject currentListing={currentListing} />
              </div>
              {!isExperienceType && (
                <div className={css.listingVideoWrapper} data-elbaction="visible:vis accm-ytvideo">
                  <ListingVideo currentListing={currentListing} />
                </div>
              )}
              <div className={css.donateWrapper} data-elbaction="visible:vis accm-donationsection">
                <Donate currentListing={currentListing} />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <DiscoverListings currentListing={currentListing} openTab={false} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </div>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
  user: null,
  hasListings: false,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  hasListings: bool.isRequired,
  user: propTypes.user,
  listings: array,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    userListingRefs,
    userId,
    hasListings,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  let listings = [];
  if (userListingRefs) {
    listings = getMarketplaceEntities(state, userListingRefs);
  }
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    listings,
    currentLanguage: state?.ui?.language ? state?.ui?.language : 'en',
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  fetchSpeculatedTransaction: (params, processAlias, txId, transitionName, isPrivileged) =>
    dispatch(speculateTransactionLP(params, processAlias, txId, transitionName, isPrivileged)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
