import React, { useEffect, useState } from 'react';
import IconReviewStarMini from '../../ListingPageComponents/ReviewRatings/IconReviewStarMini/IconReviewStarMini';

import css from './Reviews.module.css';
import avatarEmpty from '../../../../assets/icons/avatar.png';
import { FormattedMessage } from '../../../../util/reactIntl';
import { isMobile } from 'react-device-detect';
import Modal from '../Utils/Modal/Modal.jsx';
import IconReviewSearchPage from '../ReviewRatings/IconReviewSearchPage/iconReviewSearchPage.jsx';

const Reviews = currentListing => {
  // for the star rating
  const [reviewsAmount, setReviewsAmount] = useState('');
  const [socialbnbRating, setSocialbnbRating] = useState(0);
  const [finalRating, setFinalRating] = useState(0);
  const [isfetchDone, setIsFetchDone] = useState(false);
  // for the reviews
  const [reviews, setReviews] = useState([]);
  const [googleReviews, setGoogleReviews] = useState([]);
  // to track the expansion state for each review
  const [expandedReviews, setExpandedReviews] = useState([]);
  const [expandedDrawerReviews, setExpandedDrawerReviews] = useState([]);

  const [visibleReviewsCount, setVisibleReviewsCount] = useState(isMobile ? 2 : 4);
  const [visibleGoogleReviewsCount, setVisibleGoogleReviewsCount] = useState(isMobile ? 2 : 4);
  // show modal with all reviews
  const [showModal, setShowModal] = useState(false);
  const [showGoogleModal, setShowGoogleModal] = useState(false);
  const [isGoogleReviews, setIsGoogleReviews] = useState(false);
  const handleShowMoreReviews = () => {
    setShowModal(true);
    setVisibleReviewsCount(reviews.length);
  };

  const handleShowMoreGoogleReviews = () => {
    setShowGoogleModal(true);
    setVisibleGoogleReviewsCount(googleReviews.length);
  };
  const handleShowLessReviews = () => {
    setVisibleReviewsCount(isMobile ? 2 : 4);
    setShowModal(false);
    currentListing.closeModalFromOutside(false);

    setExpandedReviews(prev => {
      return prev.map(review => false);
    });
  };
  const handleShowLessGoogleReviews = () => {
    setVisibleGoogleReviewsCount(isMobile ? 2 : 4);
    setShowGoogleModal(false);
    currentListing.closeModalFromOutside(false);

    setExpandedDrawerReviews(prev => {
      return prev.map(review => false);
    });
  };

  const scrollToTop = () => {
    if (typeof window !== 'undefined' && window?.document) {
      document.body.scrollTo({
        top: 500,
        behavior: 'smooth',
      });
    }
  };

  // const [modalFromOutside , setModalFromOutside] =useState ("")

  useEffect(() => {
    // Update the showModal state when showModalFromOutside prop changes
    // setModalFromOutside(currentListing.showModalFromOutside);
    setShowModal(currentListing.showModalFromOutside);
    setShowGoogleModal(currentListing.showModalFromOutside);
    // setVisibleReviewsCount(reviews.length);
    // setVisibleGoogleReviewsCount(googleReviews.length);
  }, [currentListing.showModalFromOutside, currentListing]);

  useEffect(() => {
    if (!isfetchDone) {
      let googleReviewsFinal = currentListing.googleReviews?.length
        ? currentListing.googleReviews
        : currentListing?.currentListing?.attributes?.publicData?.location?.reviews?.length
        ? currentListing?.currentListing?.attributes?.publicData?.location?.reviews
        : currentListing?.currentListing?.attributes?.publicData?.googleReviews?.length
        ? currentListing?.currentListing?.attributes?.publicData?.googleReviews
        : [];
      let googleReviewsRating =
        typeof currentListing?.rating == 'number'
          ? currentListing?.rating
          : typeof currentListing?.currentListing?.attributes?.publicData?.location?.rating ==
            'number'
          ? currentListing?.currentListing?.attributes?.publicData?.location?.rating
          : typeof currentListing?.currentListing?.attributes?.publicData?.googleRating == 'number'
          ? currentListing?.currentListing?.attributes?.publicData?.googleRating
          : 0;
      if (currentListing.reviews.length > 0) {
        setReviewsAmount({ value: currentListing.reviews });
        const ratings = currentListing.reviews.map(review => review.attributes.rating);
        const sum = ratings.reduce((total, rating) => total + rating, 0);
        const average = sum / ratings.length;
        setSocialbnbRating(average.toFixed(1));
        setFinalRating(+average.toFixed(1));
        setReviews(currentListing.reviews);
        setGoogleReviews(googleReviewsFinal);
        setIsFetchDone(true);
        setIsGoogleReviews(false);
      } else if (currentListing.reviews.length === 0 && googleReviewsFinal.length > 0) {
        setReviewsAmount({ value: googleReviewsFinal });
        setFinalRating(+googleReviewsRating);
        setReviews([]);
        setGoogleReviews(googleReviewsFinal);
        setIsGoogleReviews(
          currentListing?.currentListing?.attributes?.publicData?.location?.totalGoogleReviews ??
            false
        );
      } else if (googleReviewsFinal.length === 0 && googleReviewsFinal.length === 0) {
        setFinalRating(0);
        setReviewsAmount({ value: 0 });
        setReviews([]);
        setGoogleReviews([]);
        setIsGoogleReviews(false);
      }

      // Initialize the expandedReviews array with false values for each review
      setExpandedReviews(Array(currentListing.reviews.length).fill(false));
      setExpandedDrawerReviews(Array(currentListing.reviews.length).fill(false));
    }
  }, [currentListing.reviews, currentListing.googleReviews]);

  // const handleContentExpand = (index) => {
  //   setExpandedReviews((prevExpandedReviews) => {
  //     const newExpandedReviews = [...prevExpandedReviews];
  //     newExpandedReviews[index] = !newExpandedReviews[index];
  //     return newExpandedReviews;
  //   });
  // };

  const handleContentExpandForModal = index => {
    handleShowMoreReviews();
    setExpandedReviews(prevExpandedReviews => {
      const newExpandedReviews = [...prevExpandedReviews];
      newExpandedReviews[index] = !newExpandedReviews[index];

      return newExpandedReviews;
    });
  };

  const handleDrawerExpand = index => {
    setExpandedDrawerReviews(prevExpandedReviews => {
      const newExpandedReviews = [...prevExpandedReviews];
      newExpandedReviews[index] = !newExpandedReviews[index];
      return newExpandedReviews;
    });
  };

  const handleContentExpandForGoogleModal = index => {
    handleShowMoreGoogleReviews();
    setExpandedReviews(prevExpandedReviews => {
      const newExpandedReviews = [...prevExpandedReviews];
      newExpandedReviews[index] = !newExpandedReviews[index];
      return newExpandedReviews;
    });
  };

  // for socialbnb reviews
  const sortedReviews = [...reviews];

  // Sort the reviews based on the 'rating' property in descending order
  sortedReviews.sort((a, b) => b.attributes.rating - a.attributes.rating);

  const arrayToBeMapped = sortedReviews.slice(0, visibleReviewsCount).map((review, index) => {
    const contentClass = expandedReviews[index] ? `${css.expandedContent}` : `${css.showLines}`;

    // author img
    const firstImage = review.author.profileImage
      ? review.author.profileImage.attributes.variants['square-small'].url
      : avatarEmpty;

    const displayName = review.author.attributes.profile.displayName ?? '';
    const words = displayName?.split(' ');
    const firstName = words[0];
    const displayDate = review.attributes.createdAt;
    // created at
    const createdAtDate = new Date(displayDate);
    const month = createdAtDate.toLocaleString('en', { month: 'long' });
    const year = createdAtDate.getFullYear();
    const dateString = `${month} ${year}`;
    // text

    const content = review.attributes.content;

    const maxWordsToShow = 20; // Maximum number of lines to show without expanding
    const calculateLineCount = text => {
      // Split the text by spaces to get an array of words
      const words = text?.length ? text.split(' ') : '';
      const wordCount = words.length;

      return wordCount;
    };

    const contentLineCount = calculateLineCount(content);
    const shouldShowFormattedMessage = contentLineCount > maxWordsToShow;

    return (
      <div
        key={index}
        id={`review-${index}`}
        className={css.reviewsContainer}
        onClick={() => handleContentExpandForModal(index)}
      >
        <div className={css.reviewsContainerLeft}>
          <img className={css.avatarImg} src={firstImage} alt="socialbnb projects avatar autor" />
          <div className={css.reviewsContainerLeftText}>
            <p className={css.name}>{firstName}</p>
            <p className={css.date}>{dateString} </p>
          </div>
        </div>
        <div className={css.containerRight}>
          <p className={`${css.content} ${contentClass}`}>{content}</p>
          <div className={css.btnTextContainer}>
            {shouldShowFormattedMessage ? (
              <div className={css.btnText} onClick={() => handleDrawerExpand(index)}>
                {expandedReviews[index] ? (
                  <div className={css.showMoreLessText}>
                    <FormattedMessage id="faq.showLess" />
                    <span className={css.Gt}>&lt;</span>
                  </div>
                ) : (
                  <div className={css.showMoreLessText}>
                    <FormattedMessage id="EditListingOffersForm.activities.showMore" />
                    <span className={css.Gt}>&gt;</span>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  });

  // for google reviews

  const sortedGoogleReviews = [...googleReviews];

  // Sort the reviews based on the 'rating' property in descending order
  sortedGoogleReviews.sort((a, b) => b.rating - a.rating);

  const googleArrayToBeMapped = sortedGoogleReviews
    .slice(0, visibleGoogleReviewsCount)
    .map((googleReview, index) => {
      const contentClass = expandedDrawerReviews[index]
        ? `${css.expandedContent}`
        : `${css.showLines}`;

      // author img
      const firstImage = googleReview.profile_photo_url
        ? googleReview.profile_photo_url
        : avatarEmpty;
      const displayName = googleReview.author_name;
      const words = displayName.split(' ');
      const firstName = words[0];

      // created at
      const displayDate = googleReview.time;
      const createdAtDate = new Date(displayDate);
      const date = new Date(createdAtDate * 1000); // Convert from seconds to milliseconds
      const month = date.toLocaleString('en', { month: 'long' });
      const year = date.getFullYear();
      const formattedDate = `${month} ${year}`;

      // text
      // const content = googleReview.text
      // const maxLinesToShow = 2; // Maximum number of lines to show without expanding

      // const calculateLineCount = (text) => {
      //   // Split the text by newline characters to get an array of lines
      //   const lines = text.split('.');
      //   return lines.length;
      // }

      let content =
        currentListing.currentLanguage == 'de'
          ? googleReview?.text?.length > 0
            ? googleReview?.text
            : googleReview.text_de
          : googleReview?.text_de?.length > 0
          ? googleReview?.text_de
          : googleReview.text;
      const maxWordsToShow = 20; // Maximum number of lines to show without expanding
      const calculateLineCount = text => {
        // Split the text by spaces to get an array of words
        const words = text?.length ? text.split(' ') : '';
        const wordCount = words?.length;

        return wordCount;
      };

      const contentLineCount = calculateLineCount(content);
      const shouldShowFormattedMessage = contentLineCount > maxWordsToShow;
      return (
        <div
          key={index}
          id={`google-review-${index}`}
          className={css.reviewsContainer}
          onClick={() => handleContentExpandForGoogleModal(index)}
          data-elbaction="click:click accm-morereviews"
        >
          <div className={css.reviewsContainerLeft}>
            <img className={css.avatarImg} src={firstImage} alt="socialbnb projects avatar autor" />
            <div className={css.reviewsContainerLeftText}>
              <p className={css.name}>{firstName}</p>
              <p className={css.date}>{formattedDate} </p>
              <div className={css.singleReviewRating}>
                <IconReviewSearchPage
                  value={googleReview?.rating}
                  reviewsAmount={{ value: '55555' }}
                  onShowModalFromOutside={() => {}}
                  isListingPage={true}
                />
              </div>
            </div>
          </div>
          <div className={css.containerRight}>
            <p className={`${css.content} ${contentClass}`}>{content}</p>
            <div className={css.btnTextContainer}>
              {shouldShowFormattedMessage ? (
                <div className={css.btnText} onClick={() => handleDrawerExpand(index)}>
                  {expandedDrawerReviews[index] ? (
                    <div className={css.showMoreLessText}>
                      <FormattedMessage id="faq.showLess" />
                      <span className={css.Gt}>&lt;</span>
                    </div>
                  ) : (
                    <div className={css.showMoreLessText}>
                      <FormattedMessage id="EditListingOffersForm.activities.showMore" />
                      <span className={css.Gt}>&gt;</span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    });

  return (
    <div>
      <IconReviewStarMini
        value={finalRating}
        reviewsAmount={reviewsAmount}
        classNameContainer="starReviews"
        onShowModalFromOutside={currentListing.closeModalFromOutside}
        isGoogleReviews={isGoogleReviews}
      />
      {reviews.length > 0 && (
        <div data-elbaction={`visible:vis accm-reviews-${reviews.length}`}>
          {showModal && (
            <Modal
              isOpen={handleShowMoreReviews}
              onClose={handleShowLessReviews}
              classNameButton="btnReviews"
            >
              {arrayToBeMapped}
            </Modal>
          )}

          <div className={css.reviewsGeneralContainer}>{arrayToBeMapped}</div>

          <button
            className={reviews.length < 3 ? `${css.openBtn} ${css.openBtn2}` : css.openBtn}
            onClick={handleShowMoreReviews}
            data-elbaction="click:click accm-morereviews"
          >
            {' '}
            <FormattedMessage id="ListingsPage.Reviews" />
          </button>
        </div>
      )}

      {reviews.length === 0 && googleReviews.length > 0 && (
        <div data-elbaction={`visible:vis accm-reviews-${googleReviews.length}`}>
          {showGoogleModal && (
            <Modal
              isOpen={handleShowMoreGoogleReviews}
              onClose={handleShowLessGoogleReviews}
              classNameButton="btnReviews"
            >
              {googleArrayToBeMapped}
            </Modal>
          )}
          <div className={css.reviewsGeneralContainer}>{googleArrayToBeMapped}</div>
          <div className={css.noReviewsContainer}>
            <FormattedMessage id="ListingsPage.Reviews.Google" />
            <button className={css.noReviewsBtn} onClick={scrollToTop}>
              <FormattedMessage id="ListingsPage.Reviews.GoogleBtn" />
            </button>
          </div>
        </div>
      )}

      {reviews.length === 0 && googleReviews.length === 0 && (
        <div data-elbaction={`visible:vis accm-reviews-0`}>
          {/** here comes when are there no reviews */}
          <div className={css.noReviewsContainer}>
            <FormattedMessage id="ListingsPage.NoReviews" />
            <button className={css.noReviewsBtn} onClick={scrollToTop}>
              <FormattedMessage id="ListingsPage.Reviews.GoogleBtn" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reviews;
